.details {
  display: none;
}

.open img {
  display: none !important;
}

.open > .details {
  display: block;
  height: 100%;
}
