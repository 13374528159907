.testimonialText {
  position: absolute;
  top: 50%;
  vertical-align: middle;
  padding: 1em 10px 1em 10px !important;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.testimonialText h1 {
  color: white !important;
}

.right-column {
  padding-left: 0 !important;
}
.grid {
  flex-wrap: wrap !important;
}
